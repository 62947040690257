import { ElementRef, TemplateRef } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { transformTypeClassIntoInterface } from '../utils';

export type TableRow = Record<string, string | Array<TableFilter> | unknown>;

export type DataleanTableColumlGeneric<T> = DataleanTableColumnWithTemplate<T> | DataleanTableColumnWithoutTemplate<T>;

interface DataleanTableBaseColumn<T> {
	columnDef: string;
	header: string;
}

export interface DataleanTableColumnWithTemplate<T> extends DataleanTableBaseColumn<T> {
	template: TemplateRef<unknown>;
	cell?: (element: T) => string;
}

export interface DataleanTableColumnWithoutTemplate<T> extends DataleanTableBaseColumn<T> {
	cell: (element: T) => string;
}

export interface DataleanTableColumn<T = unknown> extends DataleanTableBaseColumn<T> {
	template?: number | TemplateRef<unknown>;
	cell?: (element: T) => string;
}

export interface DataleanTableConfiguration<T = unknown> {
	columns: Array<DataleanTableColumn<T>>;
	filters?: boolean;
	filtersConfig?: Array<TableFilter>;
	selectable?: boolean;
	displayCheck?: (row: unknown, column?: unknown, value?: unknown) => boolean;
	customTpl?: Observable<TemplateRef<unknown> | undefined> | TemplateRef<unknown> | undefined;
	editTpl?: Observable<TemplateRef<unknown> | undefined> | TemplateRef<unknown> | undefined;
	key?: string;
	defaultSort?: SortObject[];
}

export interface TableConfiguration {
	columns: Array<TableColumn>;
	filters?: boolean;
	filtersConfig?: Array<TableFilter>;
	selectable?: boolean;
	displayCheck?: (row: unknown, column?: unknown, value?: unknown) => boolean;
	customTpl?: Observable<TemplateRef<unknown> | undefined> | TemplateRef<unknown> | undefined;
	editTpl?: Observable<TemplateRef<unknown> | undefined> | TemplateRef<unknown> | undefined;
	key?: string;
	defaultSort?: SortObject[];
}

/** Configurazione della pagina corrente */
export class Page {
	constructor(
		/** The number of elements in the page */
		public pageSize: number = 25,
		/** The total number of elements */
		public totalElements: number = 0,
		/** The total number of pages */
		public totalPages?: number,
		/** The current page number */
		public pageNumber: number = 0
	) {}
}

export type IPage = transformTypeClassIntoInterface<Page>;

export class TableFilter {
	private argv: unknown[] = [];

	constructor(public label: string, public icon: string, private cb?: (...args: never[]) => unknown, ...args: unknown[]) {
		for (const arg of args) {
			this.argv.push(arg);
		}
	}

	action() {
		if (this.cb) this.cb(...(this.argv as never[]));
	}
}

export enum SortDirection {
	'asc' = 'asc',
	'desc' = 'desc',
}

export interface SortObject {
	dir: SortDirection | undefined;
	prop: string;
}
export interface SortEvent {
	sorts: Array<SortObject>;
	column: TableColumn;
	newValue: SortDirection | undefined;
	prevValue: SortDirection | undefined;
}

export interface SelectEvent {
	selected: Array<TableRow>;
}

export interface PageChangeEvent {
	/** Numero di pagina (base 0) */
	offset: number;
	/** Dimensione della pagina */
	pageSize: number;
	/** Limite elementi mostrati nella pagina (nell'ultima pagina potrebbe essere < pageSize) */
	limit: number;
	/** Numero totale di elementi */
	count: number;
}

export interface ActivateEvent {
	cellElement: ElementRef;
	cellIndex: number;
	column: TableColumn;
	event: MouseEvent;
	row: TableRow;
	rowElement: ElementRef;
	type: string;
	value: string | number | boolean;
}

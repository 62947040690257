<addiction-search (search)="filterByName($event)"></addiction-search>

<datalean-table
	*ngIf="tableRows$ | async as tableRows"
	[columns]="baseTableConfig.columns"
	[dataSource]="tableRows"
	[maxCheckable]="limit"
	[selected]="selectedRows$ | async"
	[page]="pageSettings$ | async"
	(pageChange)="pageChanged($event)"
	(selectedChange)="selectionChanged($event)"></datalean-table>

<div class="buttons-container">
	<button (click)="close()">
		<span>{{ 'ADD' | translate }}</span>
	</button>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Editor, NgxEditorModule, NgxEditorService, Toolbar, Validators } from 'ngx-editor';

//documentation: https://sibiraj-s.github.io/ngx-editor/#/
@Component({
	selector: 'addiction-html-editor',
	templateUrl: './html-editor.component.html',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxEditorModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HtmlEditorComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorComponent implements OnDestroy, ControlValueAccessor {
	editor: Editor = new Editor();
	toolbar: Toolbar = [
		['bold', 'italic', 'underline', 'strike'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
		['link'],
		['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
	];

	@Input() readonly: boolean = false;

	formGroup = new FormGroup({
		editorContent: new FormControl<string>('', [Validators.required()]),
	});

	constructor(private ngxEditorService: NgxEditorService) {
		this.ngxEditorService.config.locals = {
			heading: 'Heading',
			h1: 'Header 1',
			h2: 'Header 2',
			h3: 'Header 3',
			h4: 'Header 4',
			h5: 'Header 5',
			h6: 'Header 6',
			url: 'URL',
			text: 'Testo',
			openInNewTab: 'Apri in una nuova tab',
			insert: 'Insert',
			remove: 'Rimuovi',
		};
	}
	public onTouched: () => void = () => {};

	writeValue(value: any): void {
		if (value && value.editorContent) {
			this.formGroup.patchValue({ editorContent: value.editorContent });
		} else {
			this.formGroup.patchValue({ editorContent: value });
		}
	}

	registerOnChange(fn: any): void {
		this.formGroup.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.formGroup.disable({ emitEvent: false });
		} else {
			this.formGroup.enable({ emitEvent: false });
		}
	}

	registerOnValidatorChange(fn: () => void): void {
		this.formGroup.statusChanges.subscribe(fn);
	}

	ngOnDestroy(): void {
		this.editor.destroy();
	}
}

import { createReducer, on } from '@ngrx/store';
import { WorkFlowConfig, WorkFlowEntity } from '../../models/workFlow';
import { WorkFlowActions } from '../../../core/state/app.actions';

export const TAG = 'workflow';

export interface WorkFlowState {
  workFlowConfig: WorkFlowConfig[];
  workFlowInstance?: WorkFlowEntity[];
}

const initialState: WorkFlowState = {
  workFlowConfig: [],
  workFlowInstance: undefined,
};

export const reducer = createReducer(
  initialState,
  on(WorkFlowActions.setWorkFlowConfig, (state: WorkFlowState, { workFlowConfig = [] }) => ({
    ...state,
    workFlowConfig,
  })),
  on(WorkFlowActions.setWorkFlowInstance, (state: WorkFlowState, { workFlowInstance = [] }) => ({
    ...state,
    workFlowInstance,
  })),
  on(WorkFlowActions.resetWorkFlow, () => structuredClone({ ...initialState })),
//  on(AuthActions.logout, () => structuredClone({ ...initialState })),
);

